<template>
  <b-sidebar
    id="edit-student-sidebar"
    hide-footer
    right 
    ref="editStudentSidebar"
    bg-variant="white"
    backdrop
    @shown="sidebarShow()"
  >
    <div class="p-2">
      <b-form>
        <h2 class="m-0 text-colorBlack">Edit Student</h2>
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack">Name</span>
            <b-form-group class="mb-0">
              <b-form-input
                id="name"
                placeholder="Name"
                v-model="name"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
        
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack">Email</span>
            <b-form-group class="mb-0">
              <b-form-input id="email" placeholder="Email" v-model="email" />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack">Phone No.</span>
            <b-form-group class="mb-0">
              <b-form-input
                id="phoneNo"
                placeholder="Phone No"
                v-model="phone"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack">NIN (Social Security No)</span>
            <b-form-group class="mb-0">
              <b-form-input
                id="nin"
                placeholder="NIN"
                v-model="nin"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder">Department</span>
            <b-form-group class="mb-0">
              <v-select label="name" v-model="department" placeholder="Select Department" :options="departments" :reduce="option => option.id" autoscroll :clearable="true"></v-select>          
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder">Class / Program</span>
            <b-form-group class="mb-0">
              <v-select label="name" v-model="classe" placeholder="Select Class" :options="classes" :reduce="option => option.id" autoscroll :clearable="true"></v-select>          
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack">Account Expiry Date</span>
            <b-form-group class="mb-0">
              <b-form-datepicker id="expiry_date" v-model="expiry_date" class="mb-2"></b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="py-1">
          <b-col class="text-left">
            <span class="font-weight-bolder text-colorBlack"
              >Upload Picture</span
            >
            <b-form-group class="mb-0">
              <vue-dropzone
                ref="myVueDropzone"
                id="dropzone"
                :options="dropzoneOptions"
                :useCustomSlot="true"
                class="bg-colorLightBlue"
                @vdropzone-file-added="handleFileAdded"
              >
                <div class="dropzone-custom-content">
                  <b-img
                    :src="
                      require('@/assets/images/icons/instructor/assignment/upload.svg')
                    "
                  />
                  <h3
                    class="dropzone-custom-title text-colorBlue mt-1 font-weight-bolder"
                  >
                    Upload File
                  </h3>
                  <div class="subtitle">
                    <p>Click browse thorough your machine</p>
                  </div>
                </div>
              </vue-dropzone>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>

      <b-form-group>
        <b-button
          block
          type="submit"
          variant="colorBlue"
          pill
          @click="submit()"
        >
          Update
        </b-button>
      </b-form-group>
    </div>
  </b-sidebar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    vueDropzone: vue2Dropzone,
  },
  props: {
    student: Object
  },
  mixins: [util],
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      nin: "",
      department: "",
      classe: "",
      expiry_date: "",
      classes: [],
      departments: [],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        maxFiles: 1,
        headers: { "My-Awesome-Header": "header value" },
      },
      image: null,
    };
  },
  async mounted() {
    const res_department = await this.getDepartmentDropDown();
    this.departments = res_department.data;

    const res_classes = await this.getClassDropDown();
    this.classes = res_classes.data;

    

  },
  methods: {
    ...mapActions({
      updateStudent: "appData/updateStudent",
      getClassDropDown: "appData/getClassDropDown",
      getDepartmentDropDown: "appData/getDepartmentDropDown",
    }),

    handleFileAdded(file) {
      this.image = file;
    },
    sidebarShow(){
      if(this.student){
        this.name = this.student.name;
        this.email = this.student.email;
        this.phone = this.student.phone;
        this.nin = this.student.nin;
        this.department = this.student.department;
        this.classe = this.student.classes;
        this.expiry_date = this.student.expiry_date;
      }
    },

    async submit() {
      try {
        const formData = new FormData();
        formData.append("name", this.name);
        formData.append("email", this.email);
        formData.append("phone", this.phone);
        formData.append("nin", this.nin);
        formData.append("department", this.department);
        formData.append("classes", this.classe);
        formData.append("expiry_date", this.expiry_date);        

        if (this.image) {
          formData.append("image", this.image); 
        }
        formData.append("editd_by", this.getLoggedInUser.id); 
        formData.append("updated_by", this.getLoggedInUser.id); 

        const res = await this.updateStudent({
          payload: formData,
          pk: this.student.id
        });
        if (res.status === 200) {
          this.$swal({
            title: "Student updated successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$refs.editStudentSidebar.hide();
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },

    reset() {
      this.name = "";
      this.phone = "";
      this.email = "";
      this.nin = "";
      this.department = "";
      this.classe = "";
      this.image = null;
      this.$refs.myVueDropzone.removeAllFiles();
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
  },

};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/quill.scss";
</style>
