<template>
  <div>
    <div class="text-right pb-2"></div>
    <div class="bg-colorWhite" style="border-radius: 20px">
      <b-row class="d-flex align-items-start justify-content-start py-1">
        <b-col md="8" class="px-3 py-1">
          <h3 class="font-weight-bolder text-colorBlack">Student to a Class</h3>
          <p class="font-weight-bold text-colorGrey">
            Your all Teacher to a Class display here.
          </p>
        </b-col>
        <b-col md="4" class="px-3 py-1 text-right">
          <b-button
            type="submit"
            variant="colorBlue"
            rounded
            class="align-items-center"
            v-b-toggle.create-student-sidebar
          >
            <feather-icon icon="PlusIcon" class="mr-50 text-colorWhite" />
            <span class="text-colorWhite">Add Student</span>
          </b-button>
        </b-col>
      </b-row>
      <b-card class="mx-2" style="border-radius: 20px">
        <div>
          <b-input-group class="mb-2" style="width: 400px; float: right;">
            <b-form-input
              v-model="filter"
              placeholder="Search..."
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" @click="clearSearch">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
        <b-table
          stacked="md"
          :fields="fields"
          :items="students"
          thead-class="tableHeadColor"
          small
          rounded
          :filter="filter"
          :filter-function="filterTable"
        >
          <template #head()="data">
            <div class="py-50">
              <span class="font-weight-bolder text-colorBlack">{{
                data.label
              }}</span>
            </div>
          </template>

          <template #cell(name)="row">
            <div class="py-1">
              <span
                class="font-weight-bold text-colorBlack m-0"
                style="font-size: 1rem"
              >
                <img
                  :src="row.item.image"
                  img-fluid
                  class="cursor-pointer"
                  width="30px"
                  height="30px"
                />
                {{ row.item.name ? row.item.name : "" }}
              </span>
            </div>
          </template>

          <template #cell(email)="row">
            <div class="py-1">
              <span
                class="font-weight-bold text-colorBlack m-0"
                style="font-size: 1rem"
              >
                {{ row.item.email ? row.item.email : "" }}
              </span>
            </div>
          </template>

          <template #cell(phone)="row">
            <div class="py-1">
              <span
                class="font-weight-bold text-colorBlack m-0"
                style="font-size: 1rem"
              >
                {{ row.item.phone ? row.item.phone : "" }}
              </span>
            </div>
          </template>

          <template #cell(nin)="row">
            <div class="py-1">
              <span
                class="font-weight-bold text-colorBlack m-0"
                style="font-size: 1rem"
              >
                {{ row.item.nin ? row.item.nin : "" }}
              </span>
            </div>
          </template>

          <template #cell(department)="row">
            <div class="py-1">
              <span
                class="font-weight-bold text-colorBlack m-0"
                style="font-size: 1rem"
              >
                {{ row.item.department_data.name ? row.item.department_data.name : "" }}
              </span>
            </div>
          </template>

          <template #cell(classes)="row">
            <div class="py-1">
              <span
                class="font-weight-bold text-colorBlack m-0"
                style="font-size: 1rem"
              >
                {{ row.item.classes_data.name ? row.item.classes_data.name : "" }}
              </span>
            </div>
          </template>

          <template #cell(manage)="row">
            <div class="text-right">
              <img
                src="@/assets/images/icons/instructor/assignment/Delete.svg"
                img-fluid
                class="mx-1 cursor-pointer"
                width="30px"
                height="30px"
                @click="removeStudent(row.item)"
              />

              <img
                src="@/assets/images/icons/instructor/assignment/Edit.svg"
                img-fluid
                width="30px"
                height="28px"
                class="cursor-pointer"
                @click="editStudent(row.item)"
              />
            </div>
          </template>
        </b-table>
        <b-pagination
          size="md"
          :total-rows="totalItems"
          v-model="currentPage"
          :per-page="perPage"
        ></b-pagination>
      </b-card>
    </div>
    <CreateStudentModal @modalClosed="onModalClosed" />
    <EditStudentModal :student="student" @modalClosed="onModalClosed" />
  </div>
</template>

<script>
import CreateStudentModal from "@/components/uniHead/classPrograms/student/CreateStudentModal.vue";
import EditStudentModal from "@/components/uniHead/classPrograms/student/EditStudentModal.vue";
import { mapActions } from "vuex";

export default {
  components: {
    CreateStudentModal,
    EditStudentModal,
  },
  data() {
    return {
      fields: [
        { key: "name", label: "Student Name" },
        { key: "email", label: "Email" },
        { key: "phone", label: "Phone" },
        { key: "nin", label: "NIN" },
        { key: "department", label: "Department" },
        { key: "classes", label: "Class/Program" },
        { key: "expiry_date", label: "Account Expiry" },
        { key: "manage", label: "" },
      ],
      max: 20,
      students: [],
      student: "",
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      filter: "", // Add filter property
    };
  },
  methods: {
    editStudent(student) {
      this.student = student;
      this.$nextTick(() => {
        this.$root.$emit('bv::toggle::collapse', 'edit-student-sidebar');
      });
    },

    ...mapActions({
      getStudents: "appData/getStudents",
      deleteStudent: "appData/deleteStudent",
    }),

    async fetchPaginatedData() {
      try {
        const res = await this.getStudents({
          pageNumber: this.currentPage,
          name: this.filter, // Pass filter as name property for search
        });
        this.students = res.data.results;
        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
      } catch (error) {
        this.displayError(error);
      }
    },
    
    async removeStudent(student) {
      this.student = student;

      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            const res = await this.deleteStudent({
              pk: this.student.id,
            });
            if (res.status === 204) {
              this.$swal({
                title: "Student deleted successfully",
                icon: "success",
              });
              if (
                (this.totalItems - 1) % this.perPage === 0 &&
                this.currentPage !== 1
              ) {
                this.currentPage -= 1;
              } else {
                await this.fetchPaginatedData();
              }
            }
          } catch (error) {
            this.show = false;
            this.displayError(error);
          }
        } else {
          this.show = false;
        }
      });
    },
    async onModalClosed() {
      await this.fetchPaginatedData();
    },

    // Clear search field
    clearSearch() {
      this.filter = "";
    },

    // Filter function for the table
    filterTable(item, filter) {
      const query = filter.toLowerCase();
      return (
        item.name.toLowerCase().includes(query) ||
        item.email.toLowerCase().includes(query) ||
        item.phone.toLowerCase().includes(query) ||
        item.nin.toLowerCase().includes(query) ||
        item.department_data.name.toLowerCase().includes(query) ||
        item.classes_data.name.toLowerCase().includes(query)
      );
    },
  },

  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
  },
  async mounted() {
    await this.fetchPaginatedData();
  },
};
</script>
